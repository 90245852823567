import { useEffect, useState } from 'react'

import _ from 'lodash'
import moment from 'moment'

import { GetVideoPlaysForMostRecentDayQuery, useGetVideoPlaysForMostRecentDayQuery } from '~/graphql/generated/schema'
import { getVideoWorkoutScript, VideoWorkoutScript } from '~/utils/videoWorkoutUtils'

export function useVideoWorkoutScript() {
  const { data, loading, error } = useGetVideoPlaysForMostRecentDayQuery()
  const [videoWorkoutScript, setVideoWorkoutScript] = useState<VideoWorkoutScript | undefined>(undefined)

  const { firstGlobalIndex, workoutResumeIndex } = computeWorkoutIndexes(data)

  useEffect(() => {
    if (loading) {
      return
    }
    getVideoWorkoutScript(firstGlobalIndex).then((result) => setVideoWorkoutScript(result))
  }, [loading, error, firstGlobalIndex])

  if (loading || error) {
    return { loading, error }
  }

  if (!videoWorkoutScript) {
    return { loading: true }
  }

  return {
    data: {
      script: videoWorkoutScript,
      workoutResumeIndex,
    },
    loading: false,
  }
}

export function computeWorkoutIndexes(data: GetVideoPlaysForMostRecentDayQuery | undefined): {
  // firstGlobalIndex tells us where today's workout starts in the global video list (i.e. the index of the first video)
  firstGlobalIndex: number
  // workoutResumeIndex tells us where, within today's workout, we should resume
  workoutResumeIndex: number
} {
  if (!data || !data.videoPlaysForMostRecentDay.length) {
    return {
      firstGlobalIndex: 0,
      workoutResumeIndex: 0,
    }
  }

  const defaultIndexes = {
    workout_slot_index: 0,
    global_video_list_index: 0,
  }

  const mostRecentWatchDateMs = data?.videoPlaysForMostRecentDay[0].finished_at ?? 0
  const todayMs = Date.now()
  const shouldStartNewWorkout = !moment(mostRecentWatchDateMs).isSame(todayMs, 'day')

  const latestWorkout = {
    firstActivity: _.minBy(data.videoPlaysForMostRecentDay, (p) => p.workout_slot_index) ?? defaultIndexes,
    lastActivity: _.maxBy(data.videoPlaysForMostRecentDay, (p) => p.workout_slot_index) ?? defaultIndexes,
  }

  const firstGlobalIndex = shouldStartNewWorkout
    ? latestWorkout.lastActivity.global_video_list_index + 1
    : latestWorkout.firstActivity.global_video_list_index

  const startIndex = shouldStartNewWorkout ? 0 : latestWorkout.lastActivity.workout_slot_index + 1
  return { firstGlobalIndex, workoutResumeIndex: startIndex }
}
