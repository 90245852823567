// This list was sourced and shuffled at 10:11PT 09/21/2022.
// If the master list is updated, this can be regenerated with
// the 'Data -> Randomize range' command in Google Docs.
// Master: https://docs.google.com/spreadsheets/d/1cW7Ra-YnFpx66Om5AjTILrLvNnduKtogF9YNC79oiYg/edit#gid=0

const primaryVideoUrlsPreShuffled = [
  'https://www.youtube.com/watch?v=Go0Mto2fOXY',
  'https://www.youtube.com/watch?v=YkblUzteA-4',
  'https://www.youtube.com/watch?v=3XymgBX-R2c',
  'https://www.youtube.com/watch?v=cEYpU6LC-OI',
  'https://www.youtube.com/watch?v=q3_fMsIcuCw',
  'https://www.youtube.com/watch?v=YCGogcqMmoA',
  'https://www.youtube.com/watch?v=XQeDYBS63lY',
  'https://www.youtube.com/watch?v=D_jevR2FvzE',
  'https://www.youtube.com/watch?v=TmwuGQIgj9A',
  'https://www.youtube.com/watch?v=FjXb97qR5C8',
  'https://www.youtube.com/watch?v=6rdlklOZs4o',
  'https://www.youtube.com/watch?v=yMaWEb4Jj5w',
  'https://www.youtube.com/watch?v=ASB8LY8LgCE',
  'https://www.youtube.com/watch?v=B8vSLgaW9WQ',
  'https://www.youtube.com/watch?v=Nx5cgKIMtRg',
  'https://www.youtube.com/watch?v=nqXQ4L2oC9Q',
  'https://www.youtube.com/watch?v=qaXfCSCHBt0',
  'https://www.youtube.com/watch?v=mSJLmWnxrPg',
  'https://www.youtube.com/watch?v=ySS-IZMPjjc',
  'https://www.youtube.com/watch?v=D-evoYS2FPQ',
  'https://www.youtube.com/watch?v=A-lDvHT2QyQ',
  'https://www.youtube.com/watch?v=_VaGS46hECc',
  'https://www.youtube.com/watch?v=xnLoToJVQH4',
  'https://www.youtube.com/watch?v=G5nZzak4TFk',
  'https://www.youtube.com/watch?v=BOmCCDu0k1Y',
  'https://www.youtube.com/watch?v=tLidy1R9t9Y',
  'https://www.youtube.com/watch?v=0HiqP8UQK_A',
  'https://www.youtube.com/watch?v=Con-UORKeg4',
  'https://www.youtube.com/watch?v=isBKoIORntI',
  'https://www.youtube.com/watch?v=zRWStd_cv4M',
  'https://www.youtube.com/watch?v=UBPWIR98bL4',
  'https://www.youtube.com/watch?v=ynW8jr7K43s',
  'https://www.youtube.com/watch?v=S13Tg3RAUW4',
  'https://www.youtube.com/watch?v=WLBQ-rAb-24',
  'https://www.youtube.com/watch?v=Vw5XclD9IlQ',
  'https://www.youtube.com/watch?v=UbR0AfzLG0A',
  'https://www.youtube.com/watch?v=hWrWeFR0EEs',
  'https://www.youtube.com/watch?v=zHNh5gYH780',
  'https://www.youtube.com/watch?v=F6oeoX9edwQ',
  'https://www.youtube.com/watch?v=VNwh90eJels',
  'https://www.youtube.com/watch?v=cjJszH88J8A',
  'https://www.youtube.com/watch?v=TAAg3AnPs04',
  'https://www.youtube.com/watch?v=Pi0yePibmlc',
  'https://www.youtube.com/watch?v=IdWqsI0DSzE',
  'https://www.youtube.com/watch?v=YqJrZ6OoqjU',
  'https://www.youtube.com/watch?v=q-9m5SnnfYU',
  'https://www.youtube.com/watch?v=ZNktVhZ4_tg',
  'https://www.youtube.com/watch?v=QZhIs3-UWSQ',
  'https://www.youtube.com/watch?v=vI5pbDFDZyI',
  'https://www.youtube.com/watch?v=EZie1xXptw0',
  'https://www.youtube.com/watch?v=0Iz4s9roZyU',
  'https://www.youtube.com/watch?v=H00kcbrDyEE',
  'https://www.youtube.com/watch?v=_gauuxjK1d8',
  'https://www.youtube.com/watch?v=au-z2jVaTNk',
  'https://www.youtube.com/watch?v=r-JiHyejpn8',
  'https://www.youtube.com/watch?v=sj7-FmIHPxU',
  'https://www.youtube.com/watch?v=O3DbjfXOgu0',
  'https://www.youtube.com/watch?v=I5KZcdAmWLw',
  'https://www.youtube.com/watch?v=zFm9_SWLVEE',
  'https://www.youtube.com/watch?v=npGMhaOR7Rw',
  'https://www.youtube.com/watch?v=JMb0DJDMIWA',
  'https://www.youtube.com/watch?v=N4O7Jgsifnw',
  'https://www.youtube.com/watch?v=uE-ewzt9Yfg',
  'https://www.youtube.com/watch?v=lqtAlOTqdsc',
  'https://www.youtube.com/watch?v=ifGOjSTwbM8',
  'https://www.youtube.com/watch?v=H9WXy8ZOKtc',
  'https://www.youtube.com/watch?v=dk8dU1BSp6Q',
  'https://www.youtube.com/watch?v=3Rx-Tjf1RzM',
  'https://www.youtube.com/watch?v=i4emwrNfxF0',
  'https://www.youtube.com/watch?v=yVmVuWhwbCo',
  'https://www.youtube.com/watch?v=YHbD_X456BA',
  'https://www.youtube.com/watch?v=twJCQcmk2B4',
  'https://www.youtube.com/watch?v=sXBYJFqLjbI',
  'https://www.youtube.com/watch?v=VsF5RVu1VKo',
  'https://www.youtube.com/watch?v=omsbv-hbXiM',
  'https://www.youtube.com/watch?v=cKU9rghuAc8',
  'https://www.youtube.com/watch?v=4IHvGHGVAM0',
  'https://www.youtube.com/watch?v=V_CNtJ-u904',
  'https://www.youtube.com/watch?v=H_aOHpn_vqQ',
  'https://www.youtube.com/watch?v=_r9Ngki0tVA',
  'https://www.youtube.com/watch?v=mXdtiC-P2No',
  'https://www.youtube.com/watch?v=TUorF-2e08k',
  'https://www.youtube.com/watch?v=TzELOczXWOY',
  'https://www.youtube.com/watch?v=xe-KOvu2PK4',
  'https://www.youtube.com/watch?v=ZwXFdsmxWsA',
  'https://www.youtube.com/watch?v=nc-2Jg6b0Dw',
  'https://www.youtube.com/watch?v=aKRKcKcSW2c',
  'https://www.youtube.com/watch?v=q6e0oNVx8Uk',
  'https://www.youtube.com/watch?v=dV9QcGs58l0',
  'https://www.youtube.com/watch?v=ferWxPUN3ig',
  'https://www.youtube.com/watch?v=sKBY4aCsnnM',
  'https://www.youtube.com/watch?v=yTTwKsx6_8Q',
  'https://www.youtube.com/watch?v=io58hl1Z0TY',
  'https://www.youtube.com/watch?v=pHqNqRwlKEw',
  'https://www.youtube.com/watch?v=_9oHeL2qI6g',
  'https://www.youtube.com/watch?v=4ZVXSNB8MTs',
  'https://www.youtube.com/watch?v=9BkykUv2GCU',
  'https://www.youtube.com/watch?v=qvbMNIViMq4',
  'https://www.youtube.com/watch?v=_5vKV7HNXlo',
  'https://www.youtube.com/watch?v=cgapf6HfInw',
  'https://www.youtube.com/watch?v=GEhSHPemt0c',
  'https://www.youtube.com/watch?v=MqJXQPnzEsw',
  'https://www.youtube.com/watch?v=4KXVPS3FYkQ',
  'https://www.youtube.com/watch?v=gBylJBxhYWw',
  'https://www.youtube.com/watch?v=gvT9MzPblA0',
  'https://www.youtube.com/watch?v=jIVKNCKg8yQ',
  'https://www.youtube.com/watch?v=87u7OeNBP8U',
  'https://www.youtube.com/watch?v=nXoQUgtCRDo',
  'https://www.youtube.com/watch?v=2EKIqr4T8Ng',
  'https://www.youtube.com/watch?v=GCpGV7fOp2M',
  'https://www.youtube.com/watch?v=5GeGvhfX4cw',
  'https://www.youtube.com/watch?v=acURl_KBiRI',
  'https://www.youtube.com/watch?v=RPKcamUosv0',
  'https://www.youtube.com/watch?v=_joRsyd5B9I',
  'https://www.youtube.com/watch?v=mg9CnHZ46EE',
  'https://www.youtube.com/watch?v=R1qI4AMU6qM',
  'https://www.youtube.com/watch?v=V3G51sJcvzg',
  'https://www.youtube.com/watch?v=RijDqDeBTLg',
  'https://www.youtube.com/watch?v=lQhgMn85HP8',
  'https://www.youtube.com/watch?v=SRnMoNqaae8',
  'https://www.youtube.com/watch?v=5lIKV7XkhcU',
  'https://www.youtube.com/watch?v=SchCZ-vDIxE',
  'https://www.youtube.com/watch?v=PlhC43feC90',
  'https://www.youtube.com/watch?v=iRCv_3SfIO8',
  'https://www.youtube.com/watch?v=rgtRccqRy8s',
  'https://www.youtube.com/watch?v=6e7mz1DC8mw',
  'https://www.youtube.com/watch?v=Hx6ZNEWydCU',
  'https://www.youtube.com/watch?v=OOQp_wFkMc4',
  'https://www.youtube.com/watch?v=oTcG5wuV-eA',
  'https://www.youtube.com/watch?v=AqwbF-cC2bw',
  'https://www.youtube.com/watch?v=w80o8VCTOjg',
  'https://www.youtube.com/watch?v=ZQifx8BpaqE',
  'https://www.youtube.com/watch?v=dr970RgZu8w',
  'https://www.youtube.com/watch?v=XEg_XuCMD2s',
  'https://www.youtube.com/watch?v=Q8xtSOEwpeI',
  'https://www.youtube.com/watch?v=wWW5JvOdXsI',
  'https://www.youtube.com/watch?v=A1lwrSrushk',
  'https://www.youtube.com/watch?v=P5TdpQXTkB0',
  'https://www.youtube.com/watch?v=io08qFvcGLE',
  'https://www.youtube.com/watch?v=YosFH1rNOGU',
  'https://www.youtube.com/watch?v=a1XVSZQl7nk',
  'https://www.youtube.com/watch?v=3GRysG_ofyw',
  'https://www.youtube.com/watch?v=RTnTEPaIU3Q',
  'https://www.youtube.com/watch?v=fNeCnkLelD8',
  'https://www.youtube.com/watch?v=TEysuDHExc4',
  'https://www.youtube.com/watch?v=kh6KMW8J3RQ',
  'https://www.youtube.com/watch?v=kFm3jf3RgTk',
  'https://www.youtube.com/watch?v=brDje8Xpa_w',
  'https://www.youtube.com/watch?v=_OTorYFF7oI',
  'https://www.youtube.com/watch?v=qGtjLGFPmXE',
  'https://www.youtube.com/watch?v=015HH9zbWHQ',
  'https://www.youtube.com/watch?v=4IOsFCieGQA',
  'https://www.youtube.com/watch?v=iH4ymyBtoKs',
  'https://www.youtube.com/watch?v=K2EaelYnsO0',
  'https://www.youtube.com/watch?v=Ng6x-EMwijA',
  'https://www.youtube.com/watch?v=_VK_EcE5KkI',
  'https://www.youtube.com/watch?v=SPES7Z2fjIY',
  'https://www.youtube.com/watch?v=US0oyjSqv0A',
  'https://www.youtube.com/watch?v=jBPgWluywc4',
  'https://www.youtube.com/watch?v=9DdZ_kpbLi4',
  'https://www.youtube.com/watch?v=oXMht0mi0xc',
  'https://www.youtube.com/watch?v=hVCNS2jQQ6g',
  'https://www.youtube.com/watch?v=ksasPjrYFTg',
  'https://www.youtube.com/watch?v=GSKj_QDf3g0',
  'https://www.youtube.com/watch?v=2d1VrCvdzbY',
  'https://www.youtube.com/watch?v=hNoTVnzGQg8',
  'https://www.youtube.com/watch?v=OOnPYFVIDR4',
  'https://www.youtube.com/watch?v=8QJzCvFkZGY',
  'https://www.youtube.com/watch?v=ebs4cG20xkg',
  'https://www.youtube.com/watch?v=QDP5owF66cU',
  'https://www.youtube.com/watch?v=fHmgF4ibmuk',
  'https://www.youtube.com/watch?v=k99ZWnQ-o4U',
  'https://www.youtube.com/watch?v=IPIBTgPlK5c',
  'https://www.youtube.com/watch?v=F4T9pNRkEWM',
  'https://www.youtube.com/watch?v=bfO_BKi5uv8',
  'https://www.youtube.com/watch?v=Ps_YUElM2EQ',
  'https://www.youtube.com/watch?v=CFRBJZQvc3o',
  'https://www.youtube.com/watch?v=juGmnnuaTYs',
  'https://www.youtube.com/watch?v=rL8lBG555fA',
  'https://www.youtube.com/watch?v=4Tij3k9LuYI',
  'https://www.youtube.com/watch?v=31pLJyReFXw',
  'https://www.youtube.com/watch?v=mQoAE1emWDE',
  'https://www.youtube.com/watch?v=uIo5V13ztjM',
  'https://www.youtube.com/watch?v=HjJN7whQ5LM',
  'https://www.youtube.com/watch?v=SVYZ06wPl44',
  'https://www.youtube.com/watch?v=6W0MHZ4jb4A',
  'https://www.youtube.com/watch?v=65_-sS8iUnI',
  'https://www.youtube.com/watch?v=_b2KG_tbHXE',
  'https://www.youtube.com/watch?v=yBn2ZOwv144',
  'https://www.youtube.com/watch?v=rZdBopDIiLo',
  'https://www.youtube.com/watch?v=brhXrlJQOnE',
  'https://www.youtube.com/watch?v=XqR0Hv6mwNU',
  'https://www.youtube.com/watch?v=YWG8-agQNsk',
  'https://www.youtube.com/watch?v=tjTauD53Ru4',
  'https://www.youtube.com/watch?v=tW3MZ6z8KNA',
  'https://www.youtube.com/watch?v=q1PGaFWOtuI',
  'https://www.youtube.com/watch?v=mj40J8lFu7E',
  'https://www.youtube.com/watch?v=8GtV4gNDZHs',
  'https://www.youtube.com/watch?v=v1DWHeouJYM',
  'https://www.youtube.com/watch?v=V1dcTfVDQDw',
  'https://www.youtube.com/watch?v=2r_Fms8uWZk',
  'https://www.youtube.com/watch?v=k9_UCwNCpYU',
  'https://www.youtube.com/watch?v=ER5lSNzCi3Y',
  'https://www.youtube.com/watch?v=i1OTQvNV1lo',
  'https://www.youtube.com/watch?v=IzfyNwNpUmA',
  'https://www.youtube.com/watch?v=GZE-rmt6cNM',
  'https://www.youtube.com/watch?v=dBjtFyktXhQ',
].map((urlString) => new URL(urlString))

// This list was sourced and shuffled at 16:17PT 10/03/2022.
// Backup videos are a lower priority than the main list, so they're shuffled, and concatenated onto the end.

const backupControlVideosPreShuffled = [
  'https://www.youtube.com/watch?v=Y3vQGxPhc-o',
  'https://www.youtube.com/watch?v=CjjNRUSX348',
  'https://www.youtube.com/watch?v=pJxRnpzZv5A',
  'https://www.youtube.com/watch?v=s7lVGhTPQAY',
  'https://www.youtube.com/watch?v=etnn0zsf8yk',
  'https://www.youtube.com/watch?v=Cint5WTF818',
  'https://www.youtube.com/watch?v=N4O7Jgsifnw',
  'https://www.youtube.com/watch?v=D5GDztHsL3U',
  'https://www.youtube.com/watch?v=TENyZNLz7s8',
  'https://www.youtube.com/watch?v=_j4Ru918V4w',
  'https://www.youtube.com/watch?v=kkjf0hCKOCE',
  'https://www.youtube.com/watch?v=bQuPctSd_GE',
  'https://www.youtube.com/watch?v=08_JeM0vhJM',
  'https://www.youtube.com/watch?v=mAEf71mMn24',
  'https://www.youtube.com/watch?v=w05d5v-eyEw',
  'https://www.youtube.com/watch?v=BMPaq8sf8Ko',
  'https://www.youtube.com/watch?v=hnlJMCK_kT4',
  'https://www.youtube.com/watch?v=rrh67-EBnzY',
  'https://www.youtube.com/watch?v=7u6Os0TDR6o',
  'https://www.youtube.com/watch?v=1CC0P7y90PU',
  'https://www.youtube.com/watch?v=3sFkqIGFjps',
  'https://www.youtube.com/watch?v=PjXHuDVCgjA',
  'https://www.youtube.com/watch?v=7u6Os0TDR6o',
  'https://www.youtube.com/watch?v=EuDnSqAo784',
  'https://www.youtube.com/watch?v=YhmyTA_N9m0',
  'https://www.youtube.com/watch?v=IFZtNpoxjQ0',
  'https://www.youtube.com/watch?v=cYYYjaBCAd4',
  'https://www.youtube.com/watch?v=GqzlFffL0W4',
  'https://www.youtube.com/watch?v=I2xARpL4J7Q',
  'https://www.youtube.com/watch?v=61pBV7vRL0Y',
  'https://www.youtube.com/watch?v=RkeMxrrpZMw',
].map((urlString) => new URL(urlString))

export const controlVideoUrlsPreShuffled = [...primaryVideoUrlsPreShuffled, ...backupControlVideosPreShuffled]
