import { NetworkError } from '@apollo/client/errors'
import _ from 'lodash'
import moment from 'moment'
import getConfig from 'next/config'

import { logApolloErrorToRollbar } from '~/utils/apolloUtils'
import { controlVideoUrlsPreShuffled } from '~/utils/controlVideoUrls'
import { logToRollbar } from '~/utils/errorUtils'

export const MAX_WORKOUT_VIDEO_COUNT = 6
export const DEFAULT_WORKOUT_VIDEO_COUNT = 4
export const MINIMUM_WORKOUT_SECONDS_TOTAL = 9.5 * 60
export const TARGET_WORKOUT_SECONDS_TOTAL = 14.5 * 60
export const MAXIMUM_WORKOUT_SECONDS_TOTAL = 19.5 * 60

const { publicRuntimeConfig } = getConfig()

type VideoWorkoutVideo = {
  youtubeId: string
  totalDuration: string
  globalVideoListIndex: number
}

export type VideoWorkoutScript = {
  videos: VideoWorkoutVideo[]
}

export async function getVideoWorkoutScript(nextVideoIndexUnbounded = 0): Promise<VideoWorkoutScript> {
  const youtubeVideos: VideoWorkoutVideo[] = _.compact(
    controlVideoUrlsPreShuffled.map((url, index) => {
      return {
        youtubeId: url.searchParams.get('v') || '',
        globalVideoListIndex: index,
        totalDuration: 'UNKNOWN',
      }
    }),
  )

  const nextVideoIndex = nextVideoIndexUnbounded % youtubeVideos.length

  const videosWithOverflow = _.concat(youtubeVideos, youtubeVideos)
  const nextVideos = videosWithOverflow.slice(nextVideoIndex, nextVideoIndex + MAX_WORKOUT_VIDEO_COUNT)
  const youTubeApiKey = publicRuntimeConfig?.youTube?.apikey
  if (!youTubeApiKey) {
    logToRollbar('No YouTube data API key is configured, so video status and duration could not be retrieved.')
    return {
      videos: nextVideos.slice(0, DEFAULT_WORKOUT_VIDEO_COUNT),
    }
  }

  const nextVideoDetails = await Promise.all(
    nextVideos.map(async (video) => await getVideoDetailsFromYouTube(video.youtubeId, youTubeApiKey)),
  )

  const finalVideoPlaylist = []
  let totalQueuedSeconds = 0

  let targetSeconds = TARGET_WORKOUT_SECONDS_TOTAL

  for (let i = 0; i < nextVideos.length && totalQueuedSeconds < targetSeconds; i++) {
    if (nextVideoDetails[i].isAvailable) {
      const durationAsSeconds = moment.duration(nextVideoDetails[i].durationISO8601).asSeconds()
      if (totalQueuedSeconds + durationAsSeconds < MAXIMUM_WORKOUT_SECONDS_TOTAL) {
        nextVideos[i].totalDuration = nextVideoDetails[i].durationISO8601
        finalVideoPlaylist.push(nextVideos[i])
        totalQueuedSeconds += durationAsSeconds
      } else {
        // If this video would bring us over our maximum time, we should stop here, as long as we've got the actual minimum workout length.
        targetSeconds = MINIMUM_WORKOUT_SECONDS_TOTAL
      }
    }
  }

  return {
    videos: finalVideoPlaylist,
  }
}

export async function getVideoDetailsFromYouTube(
  videoId: string,
  apiKey: string,
): Promise<{
  durationISO8601: string
  isAvailable: boolean
}> {
  const detailUrl = `https://youtube.googleapis.com/youtube/v3/videos?part=status%2CcontentDetails&id=${videoId}&key=${apiKey}`

  try {
    const response = await fetch(detailUrl)
    const responseJson = await response.json()
    const videoInfo = responseJson?.items?.[0]
    if (!(videoInfo?.status && videoInfo?.contentDetails)) {
      return {
        durationISO8601: moment.duration(0).toISOString(),
        isAvailable: false,
      }
    }
    return {
      durationISO8601: videoInfo.contentDetails.duration,
      isAvailable: videoInfo.status.embeddable,
    }
  } catch (e) {
    logApolloErrorToRollbar(e as NetworkError)
    console.warn(e)
  }

  return {
    durationISO8601: moment.duration(0).toISOString(),
    isAvailable: true,
  }
}
